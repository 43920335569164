<!--<div id="wrapper">
    <header class="header shadow-sm ">      
            <nav class="navbar navbar-expand-sm justify-content-between">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand ml-5">
                 <img class="imgLogo" src="../../assets/img/Logo_usofondooscuro.png">
                </a>
                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul class="navbar-nav mr-5"> 
                
                    <li class="nav-item mr-5">
                        <a class="nav-link text-light" mat-icon-button [matMenuTriggerFor]="menu">
                            <span class="mr-2 d-none d-lg-inline small">{{Cod_usuario}}</span>
                            <img class="img-profile rounded-circle" style="height: 35px;"
                                src="../../assets/img/icon/user-default-img.png">
                        </a>
                      
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item  routerLink="/Login">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                <span>Salir</span>
                              </button>
                        </mat-menu>
                    </li>
                </ul> 
            </div>
            </nav>
    </header>
    
        <app-navbar></app-navbar>
        <app-loader></app-loader>
       

</div>-->

<div class="wrapper vertical-layout navbar-floating footer-static vertical-menu-modern menu-collapsed"> 
    <!--<app-premio></app-premio>-->
    <app-loader></app-loader>       
    <app-navbar [ICON]="ICON" (APP_COLOR)="changeAppColor($event)" ></app-navbar>
    <app-menu></app-menu>

    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper p-0">
        <div class="content-header row"></div>
        <div class="content-body">
            <router-outlet></router-outlet>     
        </div>
      </div>
    </div>
  </div>