import {AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend
} from "ng-apexcharts";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CursoDetalleComponent } from './curso-detalle/curso-detalle.component';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/servicios/admin/admin.service';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow
} from 'swiper/core';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { ModalPageComponent } from './modal-page/modal-page.component';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y , EffectCoverflow]);

export type ChartOptionss = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  displayedColumns: string[] = ['Curso' , 'Fecha_Inicio','Fecha_Fin','Estudiantes' , 'Finalizados' ,'Ver_Detalle'];
  dataSource = new MatTableDataSource<_Cursos>();


  @ViewChild("chart") chart: ChartComponent;
  public chartOptions1: any;
  public chartOptions2: any;
  public counto: any;
  public Numeros: _Numeros;
  public TotalConexion: _TotalConexion[];
  public Cursos: _Cursos[];
  public LogoEmpresa: string;
  public customerChartOptions:any;
  public customerChartOptions2:any;
  public chartOptions: any;
  public chartOptions_:any;
  Cod_usuario: string
  constructor(private router: Router, public AdminService: AdminService   , public dialog: MatDialog ) { }
  dtOptions: DataTables.Settings = {};

  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {

    this.AdminService.getDatosAdmin().subscribe(x => {
      console.log(x)
      this.Numeros = x.Table[0]
      this.TotalConexion = x.Table1
      this.Cursos = x.Table2
      this.dataSource  = new MatTableDataSource<_Cursos>(this.Cursos) ,
      
      this.LogoEmpresa = x.Table3[0].LOGO_EMPRESA
      let Total: number[] = []
      let dias: string[] = []

      this.TotalConexion.forEach(x => {
        Total.push(x.TOTAL)
        dias.push(x.NOMBRE)
      })
      this.chartOptions2 = {
        series: [
          {
            name: "conectados",
            data: Total
          }
        ],
        chart: {
          height: 250,
          type: "bar"
        },
        title: {
          text: "conexiones semanales"
        },
        xaxis: {
          categories: dias
        }
      };
      this.dataSource.paginator = this.paginator;
 
    })

    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {

    this.customerChartOptions = {
      chart: {
      height: 200,
      type: 'radialBar'
    },
    colors: ['#7367f0', '#fdac5d', '#ea5455'],
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      radialBar: {
        size: 200,
        offsetY: 0,
          startAngle: 0,
          endAngle: 270,
        hollow: {
          margin: 5,
          size: "10%",
          background: "transparent",
          image: undefined
        },
        track: {
          strokeWidth: '100%',
          margin:10 
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },    
    series: [70, 52, 26],
    labels: ['Inscritos', 'Activos', 'Terminados'],
    legend: {
      show: true,
      floating: true,
      fontSize: "12px",
      position: "left",
      offsetX: 0,
      offsetY: 0,
      labels: {
        useSeriesColors: true
      },
      formatter: function(seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        horizontal: 3
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ]
      
    };
    this.customerChartOptions2 = {
      series: [
        {
          name: "Excel Avanzado",
          data: [10, 5, 10, 13, 8, 8, 15]
        },
        {
          name: "Excel Basico",
          data: [12, 6, 6, 15, 9, 9, 16]
        }
        ,
        {
          name: "Excel Basico",
          data: [15, 7, 9, 2, 0, 4, 5]
        }
      ],
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {       
        categories: [
          "07/03",
          "08/03",
          "09/03",
          "10/03",
          "11/03",
          "12/03",
          "13/03"
        ]
      }
    };
    this.chartOptions = {
      series: [
        {
          name: "Actual",
          data: [
            {
              x: "Excel Básico",
              y: 1292,
              goals: [
                {
                  name: "Expected",
                  value: 1400,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Google Docs",
              y: 4432,
              goals: [
                {
                  name: "Expected",
                  value: 5400,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Word Básico 2016",
              y: 5423,
              goals: [
                {
                  name: "Expected",
                  value: 5200,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Google Meet",
              y: 6653,
              goals: [
                {
                  name: "Expected",
                  value: 6500,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Power Point 2016",
              y: 8133,
              goals: [
                {
                  name: "Expected",
                  value: 6600,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Outlook 2016",
              y: 7132,
              goals: [
                {
                  name: "Expected",
                  value: 7500,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Access 2016",
              y: 7332,
              goals: [
                {
                  name: "Expected",
                  value: 8700,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "Office 365",
              y: 6553,
              goals: [
                {
                  name: "Expected",
                  value: 7300,
                  strokeWidth: 5,
                  strokeColor: "#775DD0"
                }
              ]
            }
          ]
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          columnWidth: "60%"
        }
      },
      colors: ["#00E396"],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual", "Expected"],
        markers: {
          fillColors: ["#00E396", "#775DD0"]
        }
      }
    };
    this.chartOptions_ = {
      series: [
        {
          name: "Activos",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
          name: "Iniciados",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        },
        {
          name: "Terminados",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Excel Básico",
          "Google Docs",
          "Word Básico 2016",
          "Google Meet",
          "Power Point 2016",
          "Outlook 2016",
          "Access 2016",
          "Office 365",
          "Legislación laboral"
        ]
      },
      yaxis: {
        title: {
          text: "$ (thousands)"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "$ " + val + " thousands";
          }
        }
      }
    };   
    this.Cod_usuario = window.sessionStorage.getItem("COD_USUARIO");    
    this.chartOptions1 = {
      series: [25],
      chart: {
        height: 250,
        type: "radialBar",
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              formatter: function (val) {
                return parseInt(val.toString(), 10).toString();
              },
              color: "#111",
              fontSize: "36px",
              show: true
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: ["Usuarios"]
    };

  }
  openDialog(id_curso) {
    const dialogRef = this.dialog.open(CursoDetalleComponent , {
      data: { idcurso: id_curso }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });    
  }

    Modal(tipo){
      const dialogRef = this.dialog.open(ModalPageComponent , {
        data: { tipo: tipo }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });    
  }

}

export interface _Numeros {
  CERTIFICADOS:number;
  ONLINE:number;
  TOTAL_CURSOS_GLOBAL:number;
  TOTAL_CURSOS: number;
  TOTAL_ESTUDIANTE: number
}
export interface _TotalConexion {
  DIA: string;
  NOMBRE: string;
  TOTAL: number;
}
export interface _Cursos {
  ID_CURSO: number;  
  IMG_CURSO: string;
  NOMBRE_CURSO: string;
  FECHA_INICIO:string;
  FECHA_FIN:string;
  TOTAL_CERTIFICADOS: string;
  TOTAL_ESTUDIANTES: string;
}

