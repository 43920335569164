
<!--
<div class="content" style="height: 100%;">
  <img src="../../assets/img/icon/g_2.png" class="img-bg-left">
  <img src="../../assets/img/icon/g_1.png" class="img-bg-right">
    <header class="header shadow-sm ">      
        <nav class="navbar navbar-expand-sm justify-content-between">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand ml-5">
             <img class="imgLogo" src="../../assets/img/Logo_usofondooscuro.png">
            </a>
         
        </nav>
</header>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="../../assets/img/icon/personaje inicio.png" alt="Image" class="img-fluid flotante">
        </div>
        <div class="col-md-6 contents">
          <div class="row justify-content-center">
             
            <div class="col-md-8 col-12">               
                <div class="card login">
              <div class="mb-4 mt-3 mx-auto">
            <img src="../../assets/img/Logo_Original.png" class="imgLogo img-center">
              <h1 class="mb-4 mt-3">Iniciar Sesión </h1>
            </div>
            <div *ngIf="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            <form name="ngForm" ngNativeValidate #form="ngForm">
              <div class="form-group first mb-3"  [ngClass]="{'field--not-empty': isFocused || Username.value.length>0}">
                <label for="user">Usuario</label>
                <input type="text" class="form-control" id="Username" name="Username" #Username [(ngModel)]="Login.Username"  required>

              </div>
              <div class="form-group last mb-4" [ngClass]="{'field--not-empty': isFocused1 || Password.value.length>0}">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="Password" name="Password" #Password [(ngModel)]="Login.Password" required >                
              </div>             
              

              <button class="btn btn-block btn-primary" (click)="login()">Entrar</button>      
            </form>
                </div>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
</div>-->
<div style="    position: fixed;
right: 50px;
top: 20px;
">By <img src="../../assets/img/Imagotipo_color.png" style="height: 30px;"></div>

<div class="pricing-free-trial" style="height: 11.71rem;
background-color: rgba(186, 191, 199, 0.12);
position: fixed;
width: 100vw;">
  <div class="row">
      <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
              <div class="text-center text-md-left mt-3">
                  <img src="https://www.carozzicorp.com/wp-content/themes/carozzi/img/logo_red.png?v=2" width="150px">           
              </div>

              <!-- image -->
              <img src="../../assets/new/img/illustration/pricing-Illustration.svg" class="pricing-trial-img img-fluid" alt="svg img">
          </div>
      </div>
  </div>
</div>

<div class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static  menu-collapsed">
  <div class="app-content content ">
    <div class="content-wrapper">
      <div class="content-header row"></div>
      <div class="content-body">
        <div class="auth-wrapper auth-v1 px-2">
          <div class="auth-inner py-2">
            <div class="card mb-0">
              <div class="card-body">
                <a class="brand-logo">
                  <h2 class="brand-text text-primary ml-1">
                    Capacitapp
                  </h2>
                </a>

                <h4 class="card-title mb-1">
                  Bienvenido a Capacitapp! 👋
                </h4>
                <p class="card-text mb-2">
                  Por favor ingresa a tu cuenta.
                </p>

                <div class="alert alert alert-danger mt-1 alert-validation-msg" *ngIf="error !== null">
                  <div class="alert-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info mr-50 align-middle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                    <span>Ups!<strong> Error!! </strong>. {{error}} .</span>
                  </div>

                </div>

                <form name="ngForm" ngNativeValidate #form="ngForm">

                  <div class="form-group first mb-3"  [ngClass]="{'field--not-empty': isFocused || Username.value.length>0}">
                    <label for="user">Usuario</label>
                    <input type="text" class="form-control" id="Username" name="Username" #Username [(ngModel)]="Login.Username"  required placeholder="demo@demo.cl">
                  </div>

                  <div class="form-group last mb-1" [ngClass]="{'field--not-empty': isFocused1 || Password.value.length>0}">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" id="Password" name="Password" #Password [(ngModel)]="Login.Password" required placeholder="**********">                
                  </div>   

                  <div class="gif" *ngIf="gif">
                    <img src="../../assets/new/img/gif/loader__.gif" alt="gif" >
                  </div>

                  <button class="btn btn-primary btn-block" (click)="login()">
                    Entrar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
