import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y , EffectCoverflow]);
@Component({
  selector: 'app-card-curso',
  templateUrl: './card-curso.component.html',
  styleUrls: ['./card-curso.component.scss']
})
export class CardCursoComponent implements OnInit {

  @Input() cursos:any[] 
  @Input() viewCursos:number;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  var  slides = Array.from({ length: 5 }).map((el, index) => `Slide ${index + 1}`);
  var virtualSlides = Array.from({ length: 600 }).map(
    (el, index) => `Slide ${index + 1}`
  );


  }

  verCurso(id_curso) {
    
    let curso_ = btoa("CursoId=" + id_curso)
    this.router.navigate(['App/Curso/' + curso_]);

  }

}
