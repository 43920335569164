import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/servicios/perfil/perfil.service';
@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {
  fileName = '';
  imageSrc: any;
  imageUpload:string;
  FileUpload:File
  constructor(
    public PerfilService: PerfilService ,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  onFileSelected(event) {
    const file:File = event.target.files[0];  
    if (file) {     

       const reader = new FileReader();
       reader.onload = e => this.imageSrc = reader.result;
       reader.readAsDataURL(file);   
       this.FileUpload = event.target.files[0]
    }
}
  selectimage(img){
      this.imageSrc = "../../../../assets/new/img/profile-imagen/" +  img;
      this.imageUpload = img;
  }
  Guardar(){
    if (this.FileUpload) {

      

         const formData: FormData = new FormData(); 
         formData.append("File", this.FileUpload);  

          this.PerfilService.upload(formData).subscribe(
            event => {
                if(event instanceof HttpResponse){
                  this.router.navigate(["/App/Inicio"]);   
                } 
            } ,
            err => {
              
            }
          )
    }
    else{
        if(this.imageUpload){
            
          const formData: FormData = new FormData(); 
          formData.append("Default", this.imageUpload);  
 
           this.PerfilService.upload(formData).subscribe(
             event => {
              
              if (event instanceof HttpResponse) {
                this.router.navigate(["/App/Inicio"]);
              }

           
             } ,
             err => {
               
             }
           )

        }
    }

  }

}
