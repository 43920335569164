<section id="dashboard" class="container-xxl">
    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Ranking</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><span>Top</span>
                            </li>
                            
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-0 row match-height">
      <div class="col-lg-6 col-md-6 col-12 offset-lg-3 offset-md-3">
             <div class="card card-profile">
                <div class="card-body mb-0 pb-1">
                    <div class="profile-image-wrapper">
                        <div class="profile-image">
                            
                            <div class="avatar">
                               
                                <img src="../../../assets/new/img/portrait/small/avatar-s-9.jpg" alt="Profile Picture" />
                                
                            </div>
                        </div>
                    </div>
                    <h3>Curtis Stone</h3>
                    <h6 class="text-muted">Malaysia</h6>                  
                    <hr class="" />
                    <div class="d-flex justify-content-around align-items-center pb-0 ">
                        <div>
                            <h6 class="text-muted font-weight-bolder">Puntos</h6>
                            <h3 class="mb-0">156</h3>
                        </div>
                        <div>
                            <h6 class="text-muted font-weight-bolder">Rank</h6>
                            <h3 class="mb-0">3</h3>
                        </div>
                    </div>
                    

                </div>
            </div>
            <div class="card p-1 mt-0">
                <div class="table-responsive">
                    <table class="table"> 
                        <thead>
                            <tr>
                                <th>Ranking</th>
                                <th>Nombre</th>
                                <th>Puntos</th>
                                
                            </tr>
                        </thead>                       
                        <tbody class="">
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="font-weight-bolder">1°</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up text-success font-medium-1"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="rounded">
                                            <div class="avatar-content mr-1">
                                                <img class="round" src="../../../assets/new/img/portrait/small/avatar-s-11.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bolder">Dixons</div>
                                            <div class="font-small-2 text-muted">meguc@ruj.io</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="text-nowrap">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bolder mb-25">3500</span>
                                        <span class="font-small-2 text-muted">puntos</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="font-weight-bolder">1°</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up text-success font-medium-1"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="rounded">
                                            <div class="avatar-content mr-1">
                                                <img class="round" src="../../../assets/new/img/portrait/small/avatar-s-11.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bolder">Dixons</div>
                                            <div class="font-small-2 text-muted">meguc@ruj.io</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="text-nowrap">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bolder mb-25">3500</span>
                                        <span class="font-small-2 text-muted">puntos</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="font-weight-bolder">1°</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up text-success font-medium-1"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="rounded">
                                            <div class="avatar-content mr-1">
                                                <img class="round" src="../../../assets/new/img/portrait/small/avatar-s-11.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bolder">Dixons</div>
                                            <div class="font-small-2 text-muted">meguc@ruj.io</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="text-nowrap">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bolder mb-25">3500</span>
                                        <span class="font-small-2 text-muted">puntos</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="font-weight-bolder">1°</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up text-success font-medium-1"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="rounded">
                                            <div class="avatar-content mr-1">
                                                <img class="round" src="../../../assets/new/img/portrait/small/avatar-s-11.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bolder">Dixons</div>
                                            <div class="font-small-2 text-muted">meguc@ruj.io</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="text-nowrap">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bolder mb-25">3500</span>
                                        <span class="font-small-2 text-muted">puntos</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="font-weight-bolder">1°</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up text-success font-medium-1"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="rounded">
                                            <div class="avatar-content mr-1">
                                                <img class="round" src="../../../assets/new/img/portrait/small/avatar-s-11.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bolder">Dixons</div>
                                            <div class="font-small-2 text-muted">meguc@ruj.io</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="text-nowrap">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bolder mb-25">3500</span>
                                        <span class="font-small-2 text-muted">puntos</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    </div>
  
</section>