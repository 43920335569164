import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LoaderService } from "../../../servicios/service/loader.service";
@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
  loading: Observable<boolean>;
  constructor(public loaderService: LoaderService) {

    this.loading = this.loaderService.loading$;



  }

  ngOnInit(): void {
  
   }
}
