<div class="app-content content pt-3">
    <div class="content-wrapper p-0">
        <div class="row match-height">
            <div class="col-8 offset-1">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-congratulations">
                            <div class="card-body text-center">
                                <img src="../../../assets/new/img/elements/decore-left.png"
                                    class="congratulations-img-left" alt="card-img-left" />
                                <img src="../../../assets/new/img/elements/decore-right.png"
                                    class="congratulations-img-right" alt="card-img-right" />
                                <div class="avatar avatar-xl bg-primary shadow">
                                    <div class="avatar-content">
                                        <img [src]="imageSrc" *ngIf="imageSrc" height="150px">
                                    </div>
                                </div>
                                <div class="text-center">
                                    <h1 class="mb-1 text-white">
                                        Hola! , Bienvenido a Capacitapp
                                    </h1>
                                    <p class="card-text m-auto w-75">
                                        Te invitamos a seleccionar una imagen o subir una foto
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card card-profile">
                            <div class="card-body p-1">
                                <div class="profile-image-wrapper">
                                    <div class="profile-image-bienvenida mr-1">
                                        <input type="file" style="display: none;" (change)="onFileSelected($event)" #fileUpload accept="image/png, image/jpeg">
                                        <div class="avatar"  (click)="fileUpload.click()">
                                            <img src="../../../../assets/new/img/profile-imagen/camera.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images1.png')">
                                        <div class="avatar" >
                                            <img src="../../../../assets/new/img/profile-imagen/images1.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images2.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images2.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images3.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images3.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-image-wrapper">
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images4.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images4.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images5.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images5.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images6.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images6.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                    <div class="profile-image-bienvenida mr-1" (click)="selectimage('images7.png')">
                                        <div class="avatar">
                                            <img src="../../../../assets/new/img/profile-imagen/images7.png"
                                                alt="Profile Picture" height="100px">
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-relief-primary" (click)="Guardar()">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>