<nav class="header-navbar navbar align-items-center floating-nav navbar-shadow navbar navbar-expand-lg navbar-light">
  <div class="navbar-container d-flex content">
    <ul class="nav navbar-nav align-items-center ml-auto">
      <li class="nav-item d-none d-lg-block">
        <a class="nav-link nav-link-style" (click)="changeColor()">
          <!--<Icon.Moon />-->

          <i-feather name="moon" class="i-feather icon" *ngIf="ICON==='moon'"></i-feather>
          <i-feather name="sun" class="i-feather icon" *ngIf="ICON==='sun'"></i-feather>
        </a>
      </li>

      <li class="nav-item dropdown dropdown-notification mr-25 " [ngClass]="{'show' : messageShow===true  , '' : messageShow===false }" (mouseenter)="messageShow=true ; menuShow=false ">
        <a class="nav-link" >
          <!--<Icon.Bell />-->
          <i-feather name="bell" class="i-feather icon"></i-feather>

        <!--  <span class="badge badge-pill badge-danger badge-up">3</span>-->
        </a>
        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right" (mouseleave)="messageShow=false">
          <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
              <h4 class="notification-title mb-0 mr-auto">Mensajes</h4>
              <div class="badge badge-pill badge-light-primary">
                0 nuevos
              </div>
            </div>
          </li>
      <!--    <li class="scrollable-container media-list">
            <a class="d-flex" href="javascript:void(0)">
              <div class="media d-flex align-items-start">
                <div class="media-left">
                  <div class="avatar">
                    <img src="../../../../assets/new/img/portrait/small/avatar-s-26.jpg" alt="avatar" width="32"
                      height="32" />
                  </div>
                </div>
                <div class="media-body">
                  <p class="media-heading">
                    <span class="font-weight-bolder">Bien Demo 🎉</span>
                    has Terminado!
                  </p>
                  <small class="notification-text">

                    has terminado tu primer curso, Muy bien.
                  </small>
                </div>
              </div>
            </a>
            <a class="d-flex">
              <div class="media d-flex align-items-start">
                <div class="media-left">
                  <div class="avatar">
                    <img src="../../../../assets/new/img/portrait/small/avatar-s-25.jpg" alt="avatar" width="32" height="32" />
                  </div>
                </div>
                <div class="media-body">
                  <p class="media-heading">
                    <span class="font-weight-bolder">
                      Nuevo Mensaje
                    </span>
                    &nbsp;Curso excel
                  </p>
                  <small class="notification-text">
                    respondieron tu pregunta
                  </small>
                </div>
              </div>
            </a>
            <a class="d-flex" href="javascript:void(0)">
              <div class="media d-flex align-items-start">
                <div class="media-left">
                  <div class="avatar bg-light-danger">
                    <div class="avatar-content">MD</div>
                  </div>
                </div>
                <div class="media-body">
                  <p class="media-heading">
                    <span class="font-weight-bolder">
                      has sido inscrito a un nuevo Curso
                    </span>
                    &nbsp;checkout
                  </p>
                  <small class="notification-text">
                    Demo , has sido inscrito al curso office
                  </small>
                </div>
              </div>
            </a>
          </li>
          <li class="dropdown-menu-footer">
            <a class="btn btn-primary btn-block" href="javascript:void(0)">
              Leer todas las notificaciones
            </a>
          </li>-->
        </ul>
      </li>
      <li class="nav-item dropdown dropdown-user" [ngClass]="{'show' : menuShow===true  , '' : menuShow===false }" (mouseenter)="menuShow=true ; messageShow=false" >
        <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" >
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">
            </span>
            <span class="user-status"></span>
          </div>
          <span class="avatar">
            <img class="round" src="https://api.capacitapp.cl{{this.avatar}}" alt="avatar" height="40" width="40" />
            <span class="avatar-status-online"></span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" (mouseleave)="menuShow=false">
         <!-- <a class="dropdown-item">
            <i class="mr-50" data-feather="user"></i>
            Perfil
          </a>-->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/Login">
            <i-feather name="power"></i-feather>
           Logout
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>