import { Component, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public ID_PERFIL: string
  public menuShow:boolean = false
  constructor() { }

  ngOnInit(): void {


    this.ID_PERFIL = window.sessionStorage.getItem("ID_PERFIL")

  }
  

}
