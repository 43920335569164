<div class="row" id="table-bordered">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Usuarios</h4>
            </div>           
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Rut</th>
                            <th>Area</th>
                            <th>Cursos</th>
                            <th>Detalle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div  class="avatar pull-up my-0 mr-1">
                                    <img src="../../../../assets/new/img/portrait/small/avatar-s-2.jpg" alt="Avatar" height="26" width="26">
                                </div>
                                <span class="font-weight-bold">Fernanda Gonzales</span>
                            </td>
                            <td>15.234.546-k</td>
                            <td>
                              RRHH
                            </td>
                            <td>
                                <span class="badge badge-pill badge-light-primary mr-1">25</span>
                            </td>
                            <td>
                                <a (click)="detalle(1)">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </a>    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  class="avatar pull-up my-0 mr-1">
                                    <img src="../../../../assets/new/img/portrait/small/avatar-s-2.jpg" alt="Avatar" height="26" width="26">
                                </div>
                                <span class="font-weight-bold">Fernanda Gonzales</span>
                            </td>
                            <td>15.234.546-k</td>
                            <td>
                              RRHH
                            </td>
                            <td>
                                <span class="badge badge-pill badge-light-primary mr-1">25</span>
                            </td>
                            <td>
                                <a (click)="detalle(1)">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </a>    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  class="avatar pull-up my-0 mr-1">
                                    <img src="../../../../assets/new/img/portrait/small/avatar-s-2.jpg" alt="Avatar" height="26" width="26">
                                </div>
                                <span class="font-weight-bold">Fernanda Gonzales</span>
                            </td>
                            <td>15.234.546-k</td>
                            <td>
                              RRHH
                            </td>
                            <td>
                                <span class="badge badge-pill badge-light-primary mr-1">25</span>
                            </td>
                            <td>
                                <a (click)="detalle(1)">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </a>    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  class="avatar pull-up my-0 mr-1">
                                    <img src="../../../../assets/new/img/portrait/small/avatar-s-2.jpg" alt="Avatar" height="26" width="26">
                                </div>
                                <span class="font-weight-bold">Fernanda Gonzales</span>
                            </td>
                            <td>15.234.546-k</td>
                            <td>
                              RRHH
                            </td>
                            <td>
                                <span class="badge badge-pill badge-light-primary mr-1">25</span>
                            </td>
                            <td>
                                <a (click)="detalle(1)">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </a>    
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>