  <!-- search header -->
  <section>
    <div class="card mb-0 pb-0" style="background-image: url('../../../../assets/new/img/banner/banner.png')">
        <div class="card-body text-center">
            <!-- main title -->
            <h2 class="text-primary">¿Tienes algún problema?</h2>

           
        </div>
    </div>
</section>
<!-- /search header -->



<!-- contact us -->
<section class="faq-contact">
    <div class="row mt-1 pt-1">      
        <div class="col-sm-6">
            <div class="card text-center faq-contact-card py-1 card faq-search">
                <div class="card-body">
                    <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                    </div>
                    <h4>+ 56 9 7194 8709</h4>
                    <span class="text-body">Llámanos!</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card text-center faq-contact-card py-1">
                <div class="card-body">
                    <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                    </div>
                    <h4>tutorias@ditraining.cl</h4>
                    <span class="text-body">Escríbenos!</span>
                </div>
            </div>
        </div>

    </div>
    <div class="row">      
        <div class="col-sm-6 offset-3">
            <div class="card text-center faq-contact-card py-1 card faq-search">
                <div class="card-body">
                    <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                    </div>
                    <h4>+56 9 7194 8709</h4>
                    <span class="text-body">whatsappéanos!</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ contact us -->
