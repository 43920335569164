<!--<div class="page-content">
    <div class="container home">
        <img src="../../../assets/img/icon/personaje_2.png" class="personaje2">
        <div class="row mt-5">
        <h1>Bienvenido  , {{datosUsuarios.NOMBRE_ESTUDIANTE}}</h1> 
        </div>  
        <div class="row">
           
            <div class="col-md-6 col-sm-12" >
                <div class="card card-empresa">
                    <div class="card-body shadow-lg">                       
                            
                <div class="row">
                    <div class="col-md-3 col-12 flotante"> <img src="../../../assets/img/icon/braind.png" style="    height: 100px;">    </div>
                    <div class="col-md-9 col-12  mt-3" >
                        <h3>Has invertido {{datosUsuarios.HORAS}} Horas en Capacitación</h3>                      
                    </div>
                </div>
                   
              </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="card card-empresa">
                    <div class="card-body shadow-lg">
                        <div class="row">
                            <div class="col-md-8 col-12">
                                <h3>Trabajador de la empresa </h3>
                                <h2 class="mt-4">{{datosUsuarios.NOMBRE_EMPRESA}} </h2>
                            </div>
                            <div class="col-md-4 col-12 mt-4">
                                <img class="imgEmpresa" src="{{datosUsuarios.LOGO_EMPRESA}}">
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <h2>Tus cursos</h2>
            
            <div class="col-sm-12" >
                <div class="row">
                    <div class="col-sm-12" >
                <ul class="cursos">
                    <li *ngFor="let curso of datosUsuarios.CURSOS">
                        <div class="card card-curso shadow-sm" >                        
                            <img src="{{curso.IMG_CURSO}}" style="border-top-left-radius: 15px;border-top-right-radius: 15px; max-width: 250px; max-width: 250px;">                       
                                <div class="card-body card-body-curse   " >
                                    <h5 class="card-title">{{curso.NOMBRE_CURSO}}</h5>
                                    <p class="card-text">{{curso.DESCRIPCION_CURSO}}</p>                                       
                                    <div class="d-flex justify-content-between">
                                        <h5 style="font-size: 15px;"><i class="fas fa-book"></i> {{curso.TOTAL_MODULOS}} Clases </h5>
                                        <h5 style="font-size: 15px;"><i class="far fa-clock"></i> {{curso.TOTAL_HORAS}} Horas </h5>
                                    </div>
                                    <div class="progress" *ngIf="curso.AVANCE>0">
                                        <div class="progress-bar bg-success" role="progressbar" [style.width]="curso.AVANCE/100 | percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{curso.AVANCE}}%</div>
                                    </div>
                                </div>
                                
                                <div class="card-footer rounded d-flex justify-content-between" *ngIf="curso.AVANCE==0">                                        
                                    <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="verCurso(curso.ID_CURSO)">Iniciar</button>
                                </div>
                                <div class="card-footer rounded d-flex justify-content-between" *ngIf="curso.AVANCE>0">                                        
                                    <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="verCurso(curso.ID_CURSO)">Continuar</button>
                                </div>
                        </div>  
                    </li>  
                                               
                </ul>     
            </div>    
                </div>  
            </div>
            
        </div>
    </div>
</div>  -->

<section id="dashboard">
  <div class="row match-height">
    <div class="col-12 col-lg-4 col-md-4">
      <div class="card card-congratulation-medal">
        <div class="card-body">
          <h5>Felicitaciones 🎉 </h5>
          <h5 style="font-size: 12px">{{datosUsuarios.NOMBRE_ESTUDIANTE}}</h5>
          <p class="card-text font-small-3">Has invertido </p>
          <h3 class="mb-75 pt-50">
            <a href="javascript:void(0);">{{datosUsuarios.HORAS}} horas</a>
          </h3>
          <p class="card-text font-small-3">En capacitación</p>
          <img src="../../../assets/new/img/illustration/badge.svg" class="congratulation-medal" alt="Medal Pic" />
        </div>
      </div>

      <!--<Horas></Horas>-->
    </div>
    <div class="col-12 col-lg-8 col-md-8">
      <div class="card card-congratulations">
        <div class="card-body text-center">
          <img src="../../../assets/new/img/elements/decore-left.png" class="congratulations-img-left"
            alt="card-img-left" />
          <img src="../../../assets/new/img/elements/decore-right.png" class="congratulations-img-right"
            alt="card-img-right" />
          <div class="avatar avatar-xl bg-secondary shadow">
            <div class="avatar-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-award font-large-1">
                <circle cx="12" cy="8" r="7"></circle>
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
              </svg>
            </div>
          </div>
          <div class="text-center">
            <h1 class="mb-1 text-white">
            Bienvenido!
            </h1>
   
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row match-height" >
    
        <div class="col-lg-4 col-md-4 col-sm-12 col-12" style="display: none;">
          <div class="row match-height">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card card-tiny-line-stats">
            <div class="card-body pb-50">
              <h6>Puntaje</h6>
              <h2 class="font-weight-bolder mb-1">550 Puntos
              </h2>
              <apx-chart [series]="dataPunto.series" [chart]="chartOptions1.chart" [grid]="chartOptions1.grid"
                [plotOptions]="chartOptions1.options" [labels]="chartOptions1.labels" [colors]="chartOptions1.colors"
                [xaxis]="chartOptions1.xaxis" [yaxis]="chartOptions1.yaxis" [tooltip]="chartOptions1.tooltip"
                [dataLabels]="chartOptions1.labels"></apx-chart>
            </div>
          </div>


        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card card-tiny-line-stats">
            <div class="card-body pb-50">
              <h6>Ranking</h6>
              <h2 class="font-weight-bolder mb-1">5° Lugar
              </h2>
              <apx-chart [chart]="chartOptions2.chart" [grid]="chartOptions2.grid" [stroke]="chartOptions2.stroke"
                [colors]="chartOptions2.colors" [series]="chartOptions2.series" [markers]="chartOptions2.markers"
                [xaxis]="chartOptions2.xaxis" [yaxis]="chartOptions2.yaxis" [tooltip]="chartOptions2.tooltip">
              </apx-chart>
            </div>
          </div>

        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card earnings-card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h4 class="card-title mb-1">Tiempo en tus cursos</h4>
                  <div class="font-small-2">Esta semana</div>
                  <h5 class="mb-1">10 Horas</h5>
                  <p class="card-text text-muted font-small-2">
                    <span class="font-weight-bolder">68.2%</span><span> mas que la
                      semana pasada.</span>
                  </p>
                </div>
                <div class="col-6">
                  <apx-chart [chart]="earningsChartOptions.chart" [dataLabels]="earningsChartOptions.dataLabels"
                    [series]="earningsChartOptions.series" [legend]="earningsChartOptions.legend"
                    [labels]="earningsChartOptions.labels" [stroke]="earningsChartOptions.stroke"
                    [colors]="earningsChartOptions.colors" [grid]="earningsChartOptions.grid"
                    [plotOptions]="earningsChartOptions.plotOptions" [responsive]="earningsChartOptions.responsive">
                  </apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-body pb-50">
              <h6>Canjea tus puntos</h6>
              <div class="card text-center mb-3">
                <button type="button" class="btn btn-success waves-effect waves-float waves-light">
                  Canjear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card card-tiny-line-stats">
            <div class="card-body pb-50">
              <h6>Ranking Global</h6>
              <div class="card text-center mb-3">
                <button type="button" class="btn btn-primary waves-effect waves-float waves-light">
                  Entrar
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-12"> 
      <div class="row match-height">
        <div class="col-xl-12 col-md-12 col-12">
          <div class="card card-revenue-budget bg-transparent"  *ngIf="this.cursos_iniciador.length!==0">
            <div class="card-header">
              <h4 class="card-title mb-50 mb-sm-0">Cursos iniciados</h4>
            </div>
            <div class="card-body">              
              <app-card-curso [cursos]="this.cursos_iniciador" [viewCursos]="3"></app-card-curso >
            </div>
          </div>

          <div class="card card-revenue-budget bg-transparent" *ngIf="this.cursos_iniciador.length===0">
            <div class="card-header">
              <h4 class="card-title mb-50 mb-sm-0">Cursos activos</h4>
            </div>
            <div class="card-body">              
              <app-card-curso [cursos]="this.cursos_activos" [viewCursos]="2"></app-card-curso>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div> 



  <div class="row row match-height" *ngIf="this.cursos_activos.length!==0">
    <div class="col-12">
      <div class="card card-revenue-budget bg-transparent">
        <div class="card-header">
          <h4 class="card-title mb-50 mb-sm-0">Cursos activos</h4>
        </div>
        <div class="card-body">              
          <app-card-curso [cursos]="this.cursos_activos" [viewCursos]="3"></app-card-curso>
        </div>
      </div>
    </div>
  </div>

</section>