import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CursoDetalleComponent } from './pages/admin/curso-detalle/curso-detalle.component';
import { InformesComponent } from './pages/admin/informes/informes.component';
import { UsuariosComponent } from './pages/admin/usuarios/usuarios.component';
import { BienvenidaComponent } from './pages/compartidas/bienvenida/bienvenida.component';
import { SoporteComponent } from './pages/compartidas/soporte/soporte.component';
import { CursoComponent } from './pages/curso/curso.component';
import { EvaluacionComponent } from './pages/evaluacion/evaluacion.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ModuloComponent } from './pages/modulo/modulo.component';
import { PagesComponent } from './pages/pages.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { AuthGuard } from './_helper/auth.guard';



const routes: Routes = [{
  path: "App",
  component: PagesComponent,
  canActivate: [AuthGuard],
  children: [{
    path: "Inicio",
    component: InicioComponent
  },
  {
    path: "Curso/:id",
    component: CursoComponent
  },

  {
    path: "Curso",
    component: CursoComponent
  }  ,
  {
    path: "Ranking",
    component: RankingComponent
  }
    ,
  {
    path: "Admin",
    component: AdminComponent
  },
  {
    path: "Usuarios",
    component: UsuariosComponent
  },
  {
    path: "CursosAdmin/:id",
    component: CursoDetalleComponent

  },
  {
    path: "Informes",
    component: InformesComponent

  },
  {
    path: "Perfil",
    component: PerfilComponent
  }
  
  ,
  {
    path: "Soporte",
    component: SoporteComponent
  }]

},
{
  path: "Modulo/:id",
  component: ModuloComponent
},
{
  path: "Login",
  component: LoginComponent
},
{
  path: "Bienvenida",
  canActivate: [AuthGuard],
  component: BienvenidaComponent
},

{
  path: "Evaluacion/:id",
  canActivate: [AuthGuard],
  component: EvaluacionComponent
} ,
{ path: "", redirectTo: "Login", pathMatch: "full" },
{ path: "*", redirectTo: "Login", pathMatch: "full" },
{ path: "**", redirectTo: "Login", pathMatch: "full" }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
