<!--<div class="sidenav shadow-sm " *ngIf="this.ID_PERFIL==='1'">
<ul>
    <li routerLinkActive="activo" routerLink="/App/Inicio" >
        <img class="icon-sidenav"   src="../../assets/img/icon/01_Home.png" >
    </li>
    <li routerLinkActive="activo" routerLink="/App/Perfil">
        <img class="icon-sidenav" routerLinkActive="activo" src="../../assets/img/icon/02_Perfil.png">
    </li>
    <li routerLinkActive="activo" routerLink="/App/Curso">
        <img class="icon-sidenav" routerLinkActive="activo"  src="../../assets/img/icon/03_Clases.png">
    </li>   
</ul>
</div>
<div class="sidenav shadow-sm " *ngIf="this.ID_PERFIL==='2'">
    <ul>
        <li routerLinkActive="activo" routerLink="/App/Admin" >
            <i class="fab fa-adn fa-3x text-light"></i>
            <i class=" " aria-hidden="true"></i>
        </li>
        <li routerLinkActive="activo" routerLink="/App/Informes" >
            <i class="fas fa-chart-pie  fa-3x text-light"></i>
            <i class=" " aria-hidden="true"></i>
        </li>
    </ul>
    </div>

-->

<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" [ngClass]=" menuShow ? 'expanded':'' " (mouseenter)="menuShow=true" (mouseleave)="menuShow=false">
  <div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item mr-auto">
        <a class="navbar-brand" href="../../../html/ltr/vertical-collapsed-menu-template/index.html">
          <span class="brand-logo">
            <img src="https://play-lh.googleusercontent.com/fo6SN0fVnYb98K7rhBmrX5WIQWz3p0oBBY4Cp2jaue8q1Uu7e42xcozDoyW8Kn0BMg=w280-h280" />
          </span>
          <h2 class="brand-text">carozzi campus</h2>
        </a>
      </li>
    </ul>
  </div>
  <div class="shadow-bottom"></div>
  <div class="main-menu-content ps ">
    <ul class="navigation navigation-main" *ngIf="this.ID_PERFIL==='1'">
      <li class="nav-item">
        <a class="d-flex align-items-center" routerLinkActive="activo" routerLink="/App/Inicio">
          <i-feather name="home" class="i-feather icon feather feather-user"></i-feather>
          <span class="menu-title text-truncate">
            Home
          </span>
        </a>
      </li>
      <li class="navigation-header">
        <i-feather name="more-horizontal" class="i-feather icon"></i-feather>
        <span class="menu-title text-truncate" data-i18n="Dashboards">Page</span>
      </li>
   <!--<li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/App/Perfil">
          <i-feather name="user" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate" data-i18n="User">
            Usuario
          </span>
        </a>
      </li>-->
   <!--  <li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/App/Ranking">
          <i-feather name="pie-chart" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate" data-i18n="Charts">
            Ranking
          </span>
        </a>
      </li>-->
      <!--<li class=" nav-item">
        <a class="d-flex align-items-center" routerLinkActive="activo" routerLink="/App/Curso">
          <i-feather name="grid" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate" data-i18n="Kanban">
            Cursos
          </span>
        </a>
      </li>-->
      <li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/App/Soporte">
          <i-feather name="life-buoy" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate">
            Soporte
          </span>
        </a>
      </li>
    </ul>

    <ul class="navigation navigation-main" *ngIf="this.ID_PERFIL==='2'">
      <li class="nav-item">
        <a class="d-flex align-items-center" routerLinkActive="activo" routerLink="/App/Admin">
          <i-feather name="home" class="i-feather icon feather feather-user"></i-feather>
          <span class="menu-title text-truncate">
            Home
          </span>
        </a>
      </li>
      <li class="navigation-header">
        <i-feather name="more-horizontal" class="i-feather icon"></i-feather>
        <span class="menu-title text-truncate" data-i18n="Dashboards">Page</span>
      </li>
      <li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/App/Usuarios">
          <i-feather name="user" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate" data-i18n="User">
            Usuarios
          </span>
        </a>
      </li>
    
      <li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/App/Soporte" >
          <i-feather name="life-buoy" class="i-feather icon"></i-feather>
          <span class="menu-title text-truncate">
            Soporte
          </span>
        </a>
      </li>
    </ul>

  </div>
</div>
