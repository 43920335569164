
<swiper
        [navigation]="true"       
        [slidesPerView]="viewCursos"  
        [spaceBetween]="15"
        [pagination]="{ clickable: true }"
>
  <ng-template swiperSlide  *ngFor="let curso of cursos ; index as i"  >

    <div class="card card-developer-meetup " >
        <div class="meetup-img-wrapper rounded-top text-center">
          <img
            src="{{curso.IMG_CURSO}}"
            alt="Meeting Pic"
            height="170"
          />
        </div>
        <div class="card-body">
          <div class="meetup-header d-flex align-items-center match-height" style="height: 80px;">
            <div class="meetup-day">
              <h3 class="mb-0">{{curso.TOTAL_MODULOS}}</h3>
              <h6 class="mb-0">Modulos</h6>
            </div>
            <div class="my-auto">
              <h4 class="card-title mb-25" style="font-size: 14px;">{{curso.NOMBRE_CURSO}}</h4>
              <p class="card-text mb-0" style="height: 80px; font-size: 10px;">
                {{curso.DESCRIPCION_CURSO}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 media">
        <!--      <div class="row">
                <div class="col-12">
                  <div class="avatar bg-light-primary rounded">
                    <div class="avatar-content">
                      <i-feather name="calendar" class="avatar-icon font-medium-3 mb-1"> </i-feather>
            
                    </div>
                  </div>
                  <div class="media-body">
                    <h6 class="mb-0">May 25, 2022</h6>
                    <small>Ago 30, 2022</small>
                  </div>
                </div>
                
              </div>     -->
            </div>
          <!--  <div class="col-6 media text-right">
              <div class="avatar-group mt-0">
                <div
                  data-toggle="tooltip"
                  data-popup="tooltip-custom"
                  data-placement="bottom"
                  data-original-title="Billy Hopkins"
                  class="avatar pull-up"
                >
                  <img src="../../../assets/new/img/portrait/small/avatar-s-10.jpg" alt="Avatar" width="33" height="33" />
                </div>
                <div
                  data-toggle="tooltip"
                  data-popup="tooltip-custom"
                  data-placement="bottom"
                  data-original-title="Amy Carson"
                  class="avatar pull-up"
                >
                  <img src="../../../assets/new/img/portrait/small/avatar-s-26.jpg" alt="Avatar" width="33" height="33" />
                </div>
                <div
                  data-toggle="tooltip"
                  data-popup="tooltip-custom"
                  data-placement="bottom"
                  data-original-title="Brandon Miles"
                  class="avatar pull-up"
                >
                  <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" width="33" height="33" />
                </div>
                <div
                  data-toggle="tooltip"
                  data-popup="tooltip-custom"
                  data-placement="bottom"
                  data-original-title="Daisy Weber"
                  class="avatar pull-up"
                >
                  <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" alt="Avatar" width="33" height="33" />
                </div>
                <div
                  data-toggle="tooltip"
                  data-popup="tooltip-custom"
                  data-placement="bottom"
                  data-original-title="Jenny Looper"
                  class="avatar pull-up"
                >
                  <img src="../../../assets/new/img/portrait/small/avatar-s-9.jpg" alt="Avatar" width="33" height="33" />
                </div>
                <h6 class="align-self-center cursor-pointer ml-50 mb-0">
                  +42
                </h6>
              </div>
            </div>-->
          </div>
          <div class="row">
            <div class="col-6 offset-3 mt-2">
              <div class="card text-center mb-0">
                <button  type="button"  class="btn btn-primary waves-effect waves-float waves-light mb-0" (click)="verCurso(curso.ID_CURSO)">
                  Entrar
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>

  </ng-template>
</swiper>

