import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { Home, MoreHorizontal, User, PieChart, Grid, LifeBuoy, Moon, Bell, Unlock, Check, Lock, Calendar, Sun, Power , X , Pocket
 } from 'angular-feather/icons';

const icons = {
  Home, MoreHorizontal, User, PieChart, Grid, LifeBuoy, Moon, Bell, Unlock, Check, Lock, Calendar, Sun, Power , X , Pocket
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }