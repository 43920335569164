<div class="card" *ngIf="data.tipo=='Cursos'">
    <div class="card-header">
        <h4 class="card-title">Cursos Disponibles</h4>
    </div>   
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Curso</th>           
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <img src="https://e1.pngegg.com/pngimages/266/11/png-clipart-macos-app-icons-microsoft-excel.png" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Excel Avanzado</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="https://e1.pngegg.com/pngimages/266/11/png-clipart-macos-app-icons-microsoft-excel.png" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Excel Avanzado</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="https://e1.pngegg.com/pngimages/266/11/png-clipart-macos-app-icons-microsoft-excel.png" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Excel Avanzado</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="https://e1.pngegg.com/pngimages/266/11/png-clipart-macos-app-icons-microsoft-excel.png" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Excel Avanzado</span>
                    </td>                  
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card" *ngIf="data.tipo=='Usuarios'">
    <div class="card-header">
        <h4 class="card-title">Usuarios Activos</h4>
    </div>   
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Usuarios</th>           
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                    
                </tr>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card" *ngIf="data.tipo=='Certificados'">
    <div class="card-header">
        <h4 class="card-title">Usuarios certificados</h4>
    </div>   
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Usuarios</th>           
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                  
                </tr>
                <tr>
                    <td>
                        <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" class="mr-75" height="20" width="20" alt="Excel">
                        <span class="font-weight-bold">Fernanda Tudela</span>
                    </td>                    
                </tr>
            
            </tbody>
        </table>
    </div>
</div>
