import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluacionService } from 'src/app/servicios/evaluacion/evaluacion.service';
import Swal from 'sweetalert2'
import * as io from 'socket.io-client';
import { timer } from 'rxjs';
const TOKEN_KEY = "auth-token";
const SOCKET_ENDPOINT = 'https://chatditraining-paqqz.ondigitalocean.app/';

@Component({
  selector: 'app-evaluacion',
  templateUrl: './evaluacion.component.html',
  styleUrls: ['./evaluacion.component.scss']
})

export class EvaluacionComponent implements OnInit {
  public chatwsp: string = "hide";
  public msg: string = "";
  public IFRAME: SafeResourceUrl;
  public Preguntas: _Preguntas[];
  public dom: DomSanitizer
  public ID_EVALUACION: number;
  socket: any;
  public lock_:number;
  constructor(
    private route: ActivatedRoute,
    public EvaluacionService: EvaluacionService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.socket = io(SOCKET_ENDPOINT);
  }
  ngOnInit(): void {
    const ram = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const token = sessionStorage.getItem(TOKEN_KEY);
    var hash: string = this.route.snapshot.paramMap.get("id");
    var data = atob(hash).split(";")
    var d1 = new URLSearchParams(atob(data[0]))
    var d2 = new URLSearchParams(atob(data[1]))
    var d3 = new URLSearchParams(atob(data[2]))
    var d4 = new URLSearchParams(atob(data[3]))

    var CursoId = d1.get('CursoId')
    var ModuloId = d2.get('ModuloId')
    var Tipo = d3.get('Tipo')
    let hash_ = btoa(CursoId + ";" + ModuloId + ";" + Tipo)
    this.Preguntas = []
    this.EvaluacionService.getEvaluacionPreguntas(hash_).subscribe(x => {
      this.Preguntas = x.Table1
      this.ID_EVALUACION = x.Table[0].EVALUACION
      var idEvaluacion = "idEvaluacion=" + this.ID_EVALUACION.toString()
      var idPregunta = "idPregunta=" + this.Preguntas[0].ID_PREGUNTA.toString()
      let token_ = "Token=" + token
      let hash_ram = "hash_ram=" + ram
      let hashIframe = btoa(btoa(idEvaluacion) + ";" + btoa(idPregunta) + ";" + btoa(token_) + ";" + btoa(hash_ram));
      this.IFRAME = this.sanitizer.bypassSecurityTrustResourceUrl(this.Preguntas[0].URL_IFRAME + "?hash=" + hashIframe);
      this.setupSocketConnection(token, ram);
      this.lock_= 0
    })
  }
  
  Volver() {
    var hash: string = this.route.snapshot.paramMap.get("id");
    var data = atob(hash).split(";")
    var d1 = new URLSearchParams(atob(data[0]))
    let curso_ = btoa("CursoId=" + d1.get('CursoId'))
    //let Modulo_ = btoa("ModuloId=" + id_modulo)
    this.router.navigate(['App/Curso/' + curso_]);
  }
  
  setupSocketConnection(tok: string, ram: string) {



    this.socket.emit('changehanel', tok + ram);
    this.socket.on('fin-broadcast', (data: any) => {

      if (this.Preguntas.find(x => x.ID_PREGUNTA == data.idPregunta)) {
        let pregunta: _Preguntas = this.Preguntas.find(x => x.ID_PREGUNTA == data.idPregunta)!
        let i = this.Preguntas.indexOf(pregunta)
        if (data.Resultado == 0) {
          this.Preguntas[i].ESTADO = 'card-mal'
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Respuesta Incorrecta',
            showConfirmButton: false,
            timer: 1500,
            didDestroy: () => {
              this.next_(tok, ram)
            }
          })

        }
        if (data.Resultado == 1) {
          this.Preguntas[i].ESTADO = 'card-bien'
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Respuesta Correcta',
            showConfirmButton: false,
            timer: 1500,
            didDestroy: () => {
              this.next_(tok, ram)
            }
          })

        }
      }
    });
  }
  next_(tok, ram) {

    let fil_ = this.Preguntas.filter(x => x.ESTADO !== 'card-sin-hacer')
    const pre = fil_.length
    var hash: string = this.route.snapshot.paramMap.get("id");
    var data = atob(hash).split(";")
    var d1 = new URLSearchParams(atob(data[0]))
    var d2 = new URLSearchParams(atob(data[1]))
    var d3 = new URLSearchParams(atob(data[2]))
    var d4 = new URLSearchParams(atob(data[3]))

    var CursoId = d1.get('CursoId')
    var ModuloId = d2.get('ModuloId')
    var Tipo = d3.get('Tipo')
    var num: number
    if (Tipo == '1') {
      num = this.Preguntas.length
    }
    if (Tipo == '2') {
      num = this.Preguntas.length
    }

    if (pre < num) {
      const token = sessionStorage.getItem(TOKEN_KEY);
      var idEvaluacion = "idEvaluacion=" + this.ID_EVALUACION.toString()
      var idPregunta = "idPregunta=" + this.Preguntas[pre].ID_PREGUNTA.toString()
      let token_ = "Token=" + tok
      let hash_ram = "hash_ram=" + ram
      let hashIframe = btoa(btoa(idEvaluacion) + ";" + btoa(idPregunta) + ";" + btoa(token_) + ";" + btoa(hash_ram));
      this.lock_= this.lock_+ 1
      this.IFRAME = this.sanitizer.bypassSecurityTrustResourceUrl(this.Preguntas[pre].URL_IFRAME + "?hash=" + hashIframe);
    }
    else {

      const numbers = timer(2000);
      numbers.subscribe(x => {
        this.fin_prueba()
      });
    }

  }
  fin_prueba() {
    Swal.fire({
      icon: 'success',
      title: 'Terminaste!',
      text: 'has completado la evaluacion',
      footer: 'Estamos cargando tus resultados',
      showConfirmButton: false,
      timer: 4000,
      didDestroy: () => {

        this.EvaluacionService.getFinEvaluacion(this.ID_EVALUACION).subscribe(x => {
       
          let curso_id = x.Table[0].ID_CURSO
          let curso_ = btoa("CursoId=" + curso_id)
          this.router.navigate(["App/Curso/" + curso_]);
        })


      }
    })
  }
  enviar_mensaje() {

    if ("" != this.msg) {

      let d = "https://api.whatsapp.com/send?phone=56953665461&text=" + this.msg
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        d = "whatsapp://send";
      }
      window.open(d, "_blank");

    }
  }
  mostrar_chat() {
    this.chatwsp = "show";
  }
  cerrar_chat() {
    this.chatwsp = "hide";
  }

}
export interface _Preguntas {
  ID_PREGUNTA: number;
  URL_IFRAME: string;
  ESTADO: string;
}
