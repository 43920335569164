<!--<div class="page-content">

    <div class="container home">
        <div class="row mt-5">
            <div class="col-md-12 col-sm-12 " > 
                <div class="alert alert-light d-flex justify-content-between" role="alert">
                <h1>Bienvenido,  <span class="text-secondary ml-3" style="font-size: 25px;">{{Cod_usuario}}</span> </h1>   
                <img src="{{this.LogoEmpresa}}" style="height: 50px;">
            </div>         
            </div>       
        </div>
        <div class="row mt-3" >
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">
                            <h1 class="card-title mx-auto">¡Estos son tus números!</h1>      
                            
                    </div>  
                    <div class="card-body" *ngIf="this.Cursos!==undefined">
                        <div class="row">
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body">

                                       <div class="single-funfact">
                                           <div class="icon">
                                           <h2>Total Usuarios</h2>
                                            </div>
                                            <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.this.Numeros.TOTAL_ESTUDIANTE" [config]="{ }"></ng9-odometer></h1>
                                            <i class="fas fa-user-graduate fa-4x" style="color:#4caf50;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body">
                                        <div class="single-funfact">
                                            <div class="icon">
                                            <h2>Total Cursos</h2>
                                             </div>
                                             <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.this.Numeros.TOTAL_CURSOS" [config]="{ }"></ng9-odometer></h1>
                                             <i class="fas fa-book fa-4x" style="color:#4caf50;"></i>
                                            
                                         </div>
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body d-flex justify-content-between ">
                                        <apx-chart
                                        [series]="chartOptions2.series"
                                        [chart]="chartOptions2.chart"
                                        [xaxis]="chartOptions2.xaxis"
                                        [title]="chartOptions2.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
        <div class="row mt-3" >
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">
                            <h1 class="card-title mx-auto">Tus Cursos contratados</h1>      
                            
                    </div>  
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12" >
                                <div class="row">
                                    <div class="col-sm-12" >
                                        <ul class="cursos">
                                            <li *ngFor="let curso of this.Cursos">
                                                <div class="card card-curso shadow-sm" >                        
                                                    <img src="{{curso.IMG_CURSO}}" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">                       
                                                        <div class="card-body" style="font-weight: 500;
                                                        font-size: 14px;
                                                        color: #9ba0a7;
                                                        line-height: 23px;">
                                                            <h5 class="card-title">{{curso.NOMBRE_CURSO}}</h5>
                                                            <p class="card-text "> <i class="mdi mdi-account" aria-hidden="true"></i> {{curso.TOTAL_ESTUDIANTES}} Usuarios</p>     
                                                            <p class="card-text"> <i class="mdi mdi-school"></i> {{curso.TOTAL_CERTIFICADOS}} Usuarios finalizados </p>                                        
                                                                                                                  
                                                        </div>
                                                        
                                                        <div class="card-footer rounded d-flex justify-content-between" >                                        
                                                            <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="openDialog(curso.ID_CURSO)">ver más</button>
                                                        </div>                                                  
                                                </div>  
                                            </li>                                           
                                                                                
                                        </ul>    
                            </div>    
                                </div>  
                            </div>              
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
</div>
</div>-->


<section id="dashboard-admin">

    <div class="row match-height">
        <!-- Greetings Card starts -->
        <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="row match-height">
                <div class="col-12">
                    <div class="card card-congratulations">
                        <div class="card-body text-center">
                            <img src="../../../assets/new/img/elements/decore-left.png" class="congratulations-img-left"
                                alt="card-img-left" />
                            <img src="../../../assets/new/img/elements/decore-right.png"
                                class="congratulations-img-right" alt="card-img-right" />
                            <div class="avatar avatar-xl bg-primary shadow">
                                <div class="avatar-content">
                                    <i data-feather="award" class="font-large-1"></i>
                                </div>
                            </div>
                            <div class="text-center">
                                <h1 class="mb-1 text-white">Bienvenido Admin,</h1>
                                <p class="card-text m-auto w-75">
                                    El <strong>45%</strong> de tus colaboradores se han certificado
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row match-height">
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Cursos Contratados</h4>
                        </div>                        
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Curso</th>
                                        <th>Usuarios</th>
                                               
                                        <th>Ver mas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src="https://cdn-icons-png.flaticon.com/512/888/888850.png" class="mr-75" height="20" width="20" alt="Angular">
                                            <span class="font-weight-bold">Excel Avanzado</span>
                                        </td>                                        
                                        <td>
                                            <div class="avatar-group">
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <span>+10</span>
                                            </div>
                                        </td>            
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                      <tr>
                                        <td>
                                            <img src="https://cdn-icons-png.flaticon.com/512/888/888850.png" class="mr-75" height="20" width="20" alt="Angular">
                                            <span class="font-weight-bold">Excel Avanzado</span>
                                        </td>                                        
                                        <td>
                                            <div class="avatar-group">
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <span>+10</span>
                                            </div>
                                        </td>            
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="https://cdn-icons-png.flaticon.com/512/888/888850.png" class="mr-75" height="20" width="20" alt="Angular">
                                            <span class="font-weight-bold">Excel Avanzado</span>
                                        </td>                                        
                                        <td>
                                            <div class="avatar-group">
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <span>+10</span>
                                            </div>
                                        </td>            
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="https://cdn-icons-png.flaticon.com/512/888/888850.png" class="mr-75" height="20" width="20" alt="Angular">
                                            <span class="font-weight-bold">Excel Avanzado</span>
                                        </td>                                        
                                        <td>
                                            <div class="avatar-group">
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                    <img src="../../../assets/new/img/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26">
                                                </div>
                                                <span>+10</span>
                                            </div>
                                        </td>            
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Usuarios Inscritos</h4>
                        </div>                        
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Usuario</th>                                                            
                                        <th>Ver mas</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr>
                                        <td>
                                            <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                            </div>
                                            <span class="font-weight-bold">Pepito Perez</span>
                                        </td>    
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                            </div>
                                            <span class="font-weight-bold">Pepito Perez</span>
                                        </td>    
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                            </div>
                                            <span class="font-weight-bold">Pepito Perez</span>
                                        </td>    
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                            </div>
                                            <span class="font-weight-bold">Pepito Perez</span>
                                        </td>    
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                <img src="../../../assets/new/img/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26">
                                            </div>
                                            <span class="font-weight-bold">Pepito Perez</span>
                                        </td>    
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow waves-effect waves-float waves-light" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash mr-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Greetings Card ends -->

        <!-- Subscribers Chart Card starts -->
        <div class="col-lg-4 col-sm-6 col-12" *ngIf="this.Numeros">
            <div class="row match-height">
                <div class="col-6">
                    <div class="card text-center" (click)="Modal('Cursos')" style="cursor: pointer;">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{this.Numeros.TOTAL_CURSOS_GLOBAL}}</h2>
                            <p class="card-text">Cursos Disponibles</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card text-center" (click)="Modal('Cursos')" style="cursor: pointer;">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{this.Numeros.TOTAL_CURSOS}}</h2>
                            <p class="card-text">Cursos Contratados</p>
                        </div>
                    </div>
                </div>              
            </div>
            <div class="row match-height">
                <div class="col-6">
                    <div class="card text-center" (click)="Modal('Usuarios')" style="cursor: pointer;">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{this.Numeros.TOTAL_ESTUDIANTE}}</h2>
                            <p class="card-text">Estudiantes inscritos</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card text-center " (click)="Modal('Certificados')" style="cursor: pointer;">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{this.Numeros.CERTIFICADOS}}</h2>
                            <p class="card-text">Estudiantes Certificados</p>
                        </div>
                    </div>
                </div>
           
            </div>
            <div class="row match-height">
                <div class="col-6">
                    <div class="card text-center">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h4 class="font-weight-bolder">Prontamente</h4>
                            <p class="card-text">Oc Activas</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card text-center">
                        <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-award font-medium-5">
                                        <circle cx="12" cy="8" r="7"></circle>
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <h4 class="font-weight-bolder">Prontamente</h4>
                            <p class="card-text">Becas Disponibles</p>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
    <div class="row match-height">
        <div class="col-12">
            <div class="card-body">
                <swiper [navigation]="true" [slidesPerView]="2" [spaceBetween]="15" [pagination]="{ clickable: true }">
                    <ng-template swiperSlide>
                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Conexión Semanal</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="customerChartOptions2.series" [chart]="customerChartOptions2.chart"
                                    [dataLabels]="customerChartOptions2.dataLabels"
                                    [stroke]="customerChartOptions2.stroke" [xaxis]="customerChartOptions2.xaxis">
                                </apx-chart>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Presupuesto</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                    [legend]="chartOptions.legend" [dataLabels]="chartOptions.dataLabels"
                                    [colors]="chartOptions.colors" [plotOptions]="chartOptions.plotOptions"></apx-chart>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template swiperSlide>

                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Avance Curso</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="chartOptions_.series" [chart]="chartOptions_.chart"
                                    [dataLabels]="chartOptions_.dataLabels" [plotOptions]="chartOptions_.plotOptions"
                                    [yaxis]="chartOptions_.yaxis" [legend]="chartOptions_.legend"
                                    [fill]="chartOptions_.fill" [stroke]="chartOptions_.stroke"
                                    [tooltip]="chartOptions_.tooltip" [xaxis]="chartOptions_.xaxis"></apx-chart>
                            </div>
                        </div>

                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>

    <!--<div class="row match-height">
        
        <div class="col-12 col-lg-4 col-md-4" >
            <div class="card">
                <div class="card-header pb-0">
                    <h4 class="card-title">Resumen Total</h4>
                </div>
                <div class="card-body statistics-body pt-0">
                    <apx-chart [chart]="customerChartOptions.chart" [colors]="customerChartOptions.colors"
                        [stroke]="customerChartOptions.stroke" [plotOptions]="customerChartOptions.plotOptions"
                        [series]="customerChartOptions.series" [labels]="customerChartOptions.labels"
                        [legend]="customerChartOptions.legend"></apx-chart>
                </div>
            </div>
        </div>
      
        <div class="col-xl-8 col-md-6 col-12">
            <div class="card card-statistics">
                <div class="card-header">
                    <h4 class="card-title">Tus numeros</h4>

                </div>
                <div class="card-body statistics-body mt-1">
                    <div class="row my-auto">
                        <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">20</h4>
                                    <p class="card-text font-small-3 mb-0">Total Cursos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-info mr-2">
                                    <div class="avatar-content">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">80</h4>
                                    <p class="card-text font-small-3 mb-0">Usuarios inscritos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-sm-0">
                            <div class="media">
                                <div class="avatar bg-light-danger mr-2">
                                    <div class="avatar-content">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">60</h4>
                                    <p class="card-text font-small-3 mb-0">Usuarios Activos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="media">
                                <div class="avatar bg-light-success mr-2">
                                    <div class="avatar-content">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">20</h4>
                                    <p class="card-text font-small-3 mb-0">Usuarios finalizados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
    <div class="row match-height">

        <div class="col-12">
            <div class="card-body">
                <swiper [navigation]="true" [slidesPerView]="2" [spaceBetween]="15" [pagination]="{ clickable: true }">
                    <ng-template swiperSlide>
                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Coneccion Semanal</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="customerChartOptions2.series" [chart]="customerChartOptions2.chart"
                                    [dataLabels]="customerChartOptions2.dataLabels"
                                    [stroke]="customerChartOptions2.stroke" [xaxis]="customerChartOptions2.xaxis">
                                </apx-chart>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Presupuesto</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                    [legend]="chartOptions.legend" [dataLabels]="chartOptions.dataLabels"
                                    [colors]="chartOptions.colors" [plotOptions]="chartOptions.plotOptions"></apx-chart>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template swiperSlide>

                        <div class="card">
                            <div class="card-header pb-0">
                                <h4 class="card-title">Avance Curso</h4>
                            </div>
                            <div class="card-body">
                                <apx-chart [series]="chartOptions_.series" [chart]="chartOptions_.chart"
                                    [dataLabels]="chartOptions_.dataLabels" [plotOptions]="chartOptions_.plotOptions"
                                    [yaxis]="chartOptions_.yaxis" [legend]="chartOptions_.legend"
                                    [fill]="chartOptions_.fill" [stroke]="chartOptions_.stroke"
                                    [tooltip]="chartOptions_.tooltip" [xaxis]="chartOptions_.xaxis"></apx-chart>
                            </div>
                        </div>

                    </ng-template>
                </swiper>
            </div>
        </div>


    </div>
    <div class="row">
        <div class="col-12">

            <div class="card invoice-list-wrapper">
                <div class="card-datatable table-responsive ">
                    <div class="card-header">
                        <h4 class="card-title mb-50 mb-sm-0">Cursos contratados</h4>
                    </div>
                    <table mat-table [dataSource]="dataSource"  style=" width: 100%;">      
                        <ng-container matColumnDef="Curso">
                            <th mat-header-cell *matHeaderCellDef> Curso </th>
                            <td mat-cell *matCellDef="let element"> 
                                <img src="{{element.IMG_CURSO}}"
                                class="mr-75" height="20" width="20"/>
                                <span >{{element.NOMBRE_CURSO}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Fecha_Inicio">
                            <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
                            <td mat-cell *matCellDef="let element">        
                                <span >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    01/03/2022</span>                      
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Fecha_Fin">
                            <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
                            <td mat-cell *matCellDef="let element">        
                                <span >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    01/03/2022</span>                      
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Estudiantes">
                            <th mat-header-cell *matHeaderCellDef>Estudiantes</th>
                            <td mat-cell *matCellDef="let element">        
                                <span class="badge badge-pill badge-light-success">{{element.TOTAL_ESTUDIANTES}}</span>                     
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Finalizados">
                            <th mat-header-cell *matHeaderCellDef>Finalizados</th>
                            <td mat-cell *matCellDef="let element">        
                                <span class="badge badge-pill badge-light-danger">{{element.TOTAL_CERTIFICADOS}}</span>            
                            </td>
                        </ng-container>  
                        <ng-container matColumnDef="Ver_Detalle">
                            <th mat-header-cell *matHeaderCellDef>Ver Detalle</th>
                            <td mat-cell *matCellDef="let element">        
                                <div class="d-flex align-items-center col-actions">

                                    <a class="mr-1" (click)="openDialog(element.ID_CURSO)" ><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-eye font-medium-2">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg></a>

                                </div> 
                            </td>
                        </ng-container>  
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      
                    </table>
                    <mat-paginator  [pageSize]="5"> </mat-paginator>
                </div>
            </div>


        </div>
    </div> -->
</section>