import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { PerfilService } from 'src/app/servicios/perfil/perfil.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public ID_PERFIL: string
  public color:string
  public menuShow:boolean = false
  public messageShow:boolean = false
  public avatar:string = null
  @Input() ICON:string = ''
  @Output() APP_COLOR = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document , public PerfilService: PerfilService) { }
  

  ngOnInit(): void {

    this.ID_PERFIL = window.sessionStorage.getItem("ID_PERFIL")
    this.color = sessionStorage.getItem("COLOR-APP")
    if(this.ID_PERFIL=="1"){
      this.PerfilService.getImgUser().subscribe(x => {
        this.avatar =  x[0].IMG_ESTUDIANTE
      });
    }
   

  }
  changeColor(){    
      this.APP_COLOR.emit('change')
  }
  showMenu(){

      this.menuShow = !this.menuShow

  }
  showMessage(){
    this.messageShow = !this.messageShow
  }

}
