import { Component, OnInit } from '@angular/core';
import { LoginService } from '../servicios/login/login.service';
import { PerfilService } from '../servicios/perfil/perfil.service';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { Router } from '@angular/router';
const TOKEN_KEY = "auth-token";
const COD_USUARIO = "COD_USUARIO";
const ID_PERFIL = "ID_PERFIL";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isFocused: boolean = false;
  isFocused1: boolean = false;
  Logon: FormGroup;
  Login: Login = { Username: "", Password: "" };
  error: string = null;
  ID_PERFIL: string = ""
  gif:boolean=false

  constructor(
    public LoginService: LoginService,
    public PerfilService:PerfilService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {
    this.createLoginForm()
  }
  createLoginForm() {
    this.Logon = this.formBuilder.group({
      Username: [""],
      Password: [""]
    });
  }
  formControl = new FormControl("", [
    Validators.required
    // Validators.email,
  ]);

  ngOnInit(): void {

    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)){
     
    }
  

    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(COD_USUARIO);
    window.sessionStorage.removeItem(ID_PERFIL);
  }
  login() {

    this.gif = true

    this.LoginService.login(this.Login).subscribe(data => {
      this.gif = false

      this.ID_PERFIL = window.sessionStorage.getItem("ID_PERFIL")
      console.log(this.ID_PERFIL)
      if (this.ID_PERFIL == "1"){
        this.PerfilService.getBienvenida().subscribe(x=>{
          const data = x[0].data
           if(data==="Bienvenida_full"){
            this.router.navigate(["/Bienvenida"]);
           }          
        } , 
        error => {           
            if (error.error.Message=="Sin Registros"){
              this.router.navigate(["/App/Inicio"]);   
            }
        });
      }
     if (this.ID_PERFIL == "2")
        this.router.navigate(["/App/Admin"]);
    },
      error => {
        console.log(error)
        this.gif = false
        this.error = error.error;
      });
  }

}

export class Login {
  Username: string
  Password: string
}
