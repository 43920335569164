import { Component, OnInit } from '@angular/core';
import { InicioService } from 'src/app/servicios/inicio/inicio.service';
import { Router } from '@angular/router';
import {
  ApexChart ,
  ApexGrid , 
  ApexPlotOptions , 
  ApexLegend , 
  ApexDataLabels ,
  ApexXAxis ,
  ApexYAxis ,
  ApexTooltip,
  ApexNonAxisChartSeries ,
  ApexMarkers,
  ApexStroke,
  
} from "ng-apexcharts";

import { purecounterjs } from '@srexi/purecounterjs'
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  public info = "#00cfe8";
  public chartcurso:any;
  public chartOptions1: Partial<ChartOptions>; 
  public chartOptions2: any; 
  public earningsChartOptions:any;
  public datosUsuarios: DatosUsuario = {
    ID_ESTUDIANTE: 0,
    NOMBRE_ESTUDIANTE: "",
    NOMBRE_EMPRESA: "",
    LOGO_EMPRESA: "",
    CURSOS: [],
    HORAS: 0
  }
  public cursos_iniciador:any[]=[]
  public cursos_activos:any[]=[]
  dataPunto = {
    title: "Puntaje",
    statistics: "550 Puntos",
    series: [
      {
        name: "2020",
        data: [45, 85, 65, 45, 65],
      },
    ],
  };


  constructor(
    public InicioService: InicioService,
    private router: Router
  ) {


  }

  ngOnInit(): void {
    this.InicioService.getDatosUsuarios().subscribe(data => {
      this.datosUsuarios = data        
      console.log(data);
      this.cursos_iniciador = this.datosUsuarios.CURSOS.filter(x=> x.AVANCE>0);
      this.cursos_activos = this.datosUsuarios.CURSOS.filter(x=> x.AVANCE===0);
    
    })

    var $earningsStrokeColor2 = "#28c76f66";
    var $earningsStrokeColor3 = "#28c76f33";
   
    this.chartcurso =  {
      chart: {
        height: 140,
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      colors: ["#51e5a8"],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: "60%",
          },
          track: {
            background: "#ebe9f1",
            strokeWidth: "30%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#5e5873",
              fontSize: "20px",
              fontWeight: "400",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#28c76f"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      series: [83],
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          bottom: 0,
        },
      },      
    }
    

    this.chartOptions1 = {
      chart: {
        type:"bar" ,       
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: -15,
          bottom: -15,
        },
      },
      options: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          colors: {
            backgroundBarColors: [
              "#f3f3f3",
              "#f3f3f3",
              "#f3f3f3",
              "#f3f3f3",
              "#f3f3f3",
            ],
            backgroundBarRadius: 5,
          },
        },
      },
      
      legens: {
        show: false,
      },
      labels: {
        enabled: false,
      },
      
      colors: ["#ff9f43"],
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels:{
          show:false
        },
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }


    this.chartOptions2 ={
      chart: {
        type:"line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        borderColor: "#EBEBEB",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -30,
          bottom: -10,
        },
      },
      stroke: {
        width: 3,
      },
      colors: [this.info],
      series: [
        {
          name:"ranking",
          data: [0, 20, 5, 30, 15, 45],
        },
      ],
      markers: {
        size: 2,
        colors: this.info,
        strokeColors: this.info,
        strokeWidth: 2,
        strokeOpacity: 1,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#ffffff",
            strokeColor: this.info,
            size: 5,
          },
        ],
        shape: "circle",
        radius: 2,
        hover: {
          size: 3,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "0px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }

    this.earningsChartOptions = {
      chart: {
        type: "donut",
        height: 120,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      series: [50, 30, 20],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ["Excel", "Word", "office"],
      stroke: { width: 0 },
      colors: [
        $earningsStrokeColor2,
        $earningsStrokeColor3,
        "#28c76f",
      ],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20,
        },
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15,
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val) + "%";
                },
              },
              total: {
                show: true,
                offsetY: 15,
                label: "Excel",
                formatter: function (w) {
                  return "50%";
                },
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100,
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120,
            },
          },
        },
        {
          breakpoint: 1045,
          options: {
            chart: {
              height: 100,
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120,
            },
          },
        },
      ],
    };
      
  }
  verCurso(id_curso) {
    let curso_ = btoa("CursoId=" + id_curso)
    //let Modulo_ = btoa("ModuloId=" + id_modulo)
    this.router.navigate(['App/Curso/' + curso_]);
  }

}
export interface DatosUsuario {
  ID_ESTUDIANTE: number;
  NOMBRE_ESTUDIANTE: string;
  NOMBRE_EMPRESA: string;
  LOGO_EMPRESA: string;
  CURSOS: CURSOS[];
  HORAS: number;
}
export interface CURSOS {
  ID_CURSO: number
  NOMBRE_CURSO: string;
  CATEGORIA: string;
  DESCRIPCION_CURSO: string;
  IMG_CURSO: string;
  COLOR: string;
  TOTAL_MODULOS: number;
  TOTAL_HORAS: number;
  AVANCE: number;
}
export type ChartOptions = {

  chart:ApexChart ,
  grid: ApexGrid , 
  options: ApexPlotOptions , 
  legens: ApexLegend , 
  labels: ApexDataLabels ,
  
  colors: String[],
  xaxis: ApexXAxis ,
  yaxis: ApexYAxis ,
  tooltip: ApexTooltip,
  series: ApexNonAxisChartSeries ,

}
export type ChartOptions2 = {

  chart:ApexChart ,
  grid:ApexGrid,
  stroke:ApexStroke ,
  colors:String[],
  series:number[],
  markers:ApexMarkers,
  xaxis:ApexXAxis,
  yaxis:ApexYAxis,
  tooltip:ApexTooltip
}