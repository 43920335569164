import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  public COLOR:string = "light-layout"
  public ICON:string = 'moon'
  Cod_usuario: string
  color_session:string
  constructor(@Inject(DOCUMENT) private document: Document) { 

    this.color_session = sessionStorage.getItem("COLOR-APP")

  }

  ngOnInit(): void { 

   if (this.color_session===null){ 
      sessionStorage.setItem("COLOR-APP" , this.COLOR);
      this.document.body.classList.remove('light-layout'  , 'dark-layout')
      this.document.body.classList.add(this.COLOR);
      this.ICON = 'moon'
   }
   else{
      this.COLOR = sessionStorage.getItem("COLOR-APP");
      this.document.body.classList.remove('light-layout'  , 'dark-layout')
      this.document.body.classList.add(this.COLOR);
      this.ICON = 'sun'
   }
    this.Cod_usuario = window.sessionStorage.getItem("COD_USUARIO");
  }

  changeAppColor(color){

    this.color_session = sessionStorage.getItem("COLOR-APP")

    if(this.color_session==='light-layout'){
      this.COLOR = 'dark-layout'
      sessionStorage.setItem("COLOR-APP" , this.COLOR);
      this.document.body.classList.remove('light-layout'  , 'dark-layout')
      this.document.body.classList.add(this.COLOR);
      this.ICON = 'sun'
    }
    else{
      this.COLOR = 'light-layout'
      sessionStorage.setItem("COLOR-APP" , this.COLOR);
      this.document.body.classList.remove('light-layout'  , 'dark-layout')
      this.document.body.classList.add(this.COLOR);
      this.ICON = 'moon'
    }

  }

}
