import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from "@angular/common/http";
import { map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  private readonly API_URL = URL_SERVICIOS + "api/Perfil";
  constructor(private httpClient: HttpClient  ,  public http: HttpClient) { }

  getNivel() {
    let url = this.API_URL + "/Nivel";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  getTrofeos() {
    let url = this.API_URL + "/Trofeos";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  getTotalCursosCategoria() {
    let url = this.API_URL + "/TotalCursosCategoria";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  getTotaHoras() {
    let url = this.API_URL + "/TotaHoras";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  getBienvenida() {
    let url = this.API_URL + "/Bienvenida";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  getImgUser() {
    let url = this.API_URL + "/ImgUser";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  upload(formData: FormData): Observable<HttpEvent<any>> {  
    const req = new HttpRequest(
      "POST",
      `${this.API_URL}/SubirFoto`,
      formData,
      {
        reportProgress: true,
        responseType: "json"
      }
    );

    return this.http.request(req);
  }

}
